import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ContactAlertLayer from './ContactAlertLayer';
import ContactLayer from './ContactLayer';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Contact() {
    const { t, i18n, language } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    console.log({language});


    const ContactAlertLayerRef = useRef();
    const ContactLayerRef = useRef();
    const openModal = () => {
        console.log(`/static/file/${pt('company_profile_file')}`);
        ContactLayerRef.current.openModal();
    };
    const openAlertModal = () => {
        ContactAlertLayerRef.current.openModal();
    };
    useEffect(() => {
        $(window).scrollTop(0);
    }, []);

    return (
        <>
            <div id="container" className="cont_contact">
                <section className="section_top">
                    <div className="page_cover">
                        <div className="inner">
                            <h2 className="cont_title">
                                {pt('Contact_title')}
                                {/* Contact */}
                            </h2>
                            <p className="desc">

                                <span className="hidden_sm">
                                    {pt('Contact_1')}
                                    {/* 한국어 1 글자의 유니코드 사이즈가 2bytes 입니다.
                                    <br />
                                    “투바이트는 회사 이름처럼 한 글자, 한 게임, 한 사람을 소중하게
                                    여깁니다.
                                    <br />
                                    <strong>이런 마음을 계속 간직하겠습니다.”</strong> */}
                                </span>

                                <span className="visible_sm">
                                    {pt('Contact_2')}
                                    {/* 2bytes는 회사 이름처럼
                                    <br />한 글자, 한 게임, 한 사람을 소중하게 여깁니다. */}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="tab_wrap text_tab">
                        <div className="tab_box">
                            <ul className="tab_list clear">
                                <li className="col1 on">
                                    <strong className="text" style={{ color: "#1dd1d3" }}>
                                        {pt('Contact_title')}
                                        {/* Contact */}
                                    </strong>

                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="section_discript">
                    <div className="inner">
                        <strong className="title">
                            <span className="hidden_sm">
                                {pt('Contact_3')}
                                {/* 투바이트에 탑승을 환영합니다! */}
                            </span>
                            <span className="visible_sm">
                                {pt('Contact_4')}
                                {/* 투바이트에 탑승을<br />
                                환영합니다! */}
                            </span>
                        </strong>
                        <div className="desc">
                            {pt('Contact_5')}
                            {/* 2bytes를 믿고 맡겨 주실 여러분들을 환영합니다!
                            <br />
                            게임 개발 단계에 따라 제공 가능한 서비스가 다양합니다.
                            <br />
                            필요한 각종 문의/업무의뢰를 아래 메일로 보내주세요.
                            <br />
                            빠르게 회신 드리겠습니다. */}
                        </div>
                    </div>
                </section>

                <section className="section_contact">
                    <div className="inner">
                        <div className="contactUs">
                            <div className="box">
                                <p style={{ marginBottom: '8px' }}>
                                    {pt('Contact_6')}
                                    {/* 문의메일 */}
                                    {' : '}
                                    <a
                                        href="mailto:contact@2bytescorp.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        contact@2bytescorp.com
                                    </a>
                                </p>
                                <p style={{ marginBottom: '8px' }}>
                                    {pt('Contact_7')}
                                    {/* 업무문의 */}
                                    {' : '}
                                    <a href="tel:031-423-0758">
                                        {t('Contact_tel')}
                                        {/* 031-423-0758 */}
                                    </a>
                                </p>
                                <p>
                                    {pt('Contact_8')}
                                    {/* 찾아오시는 곳 */}
                                    {' : '}
                                    <a href="http://kko.to/ABcEVtiqIS)" target="_blank" rel="noreferrer">
                                        {pt('Contact_9')}
                                        {/* 경기도 안양시 동안구 부림로 170번길 38. */}
                                    </a> </p>
                            </div>

                            <div className="btn_area">
                                <button
                                    type="button"
                                    className="btn_download"
                                    onClick={openModal}
                                >
                                    {pt('Contact_10')}
                                    {/* 회사소개서 다운로드 <small>(PDF, 2M)</small> */}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_bottom">
                    <div className="inner d_table">
                        <div className="d_cell text_white">
                            {pt('Contact_11')}
                            {/* 채용 관련 문의처를 찾으세요?
                            <br />
                            채용 홈페이지 게시판을 이용해주시기 바랍니다. */}
                        </div>
                        <div className="d_cell btn_area">
                            <Link
                                to={`/${i18n.language}/Careers/Life`}
                                className="btn_basic btn_xl btn_pop_open"
                                rel="noreferrer"
                            >
                                {pt('Contact_12')}
                                {/* 채용 알아보기 */}
                            </Link>
                        </div>
                    </div>
                </section>

                <ContactLayer
                    ref={ContactLayerRef}
                    openContactAlertLayer={openAlertModal}
                />
                <ContactAlertLayer ref={ContactAlertLayerRef} />
            </div>
        </>
    );
}

export default Contact;
