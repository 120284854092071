import React, { useEffect } from 'react';
import SectionTop from '../layout/SectionTop';
import Why2Bytes from '../layout/Why2Bytes';
import SectionBottom from '../layout/SectionBottom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Content() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    return (
        <>
            <section className="section_discript">
                <div className="inner">
                    <strong className="title">
                        {pt('Localization_1')}
                        {/* 2byte의 로컬라이제이션 <br className="visible_sm" />
                        핵심 3가지 */}
                    </strong>

                    <div className="step_box">
                        <dl>
                            <dt style={{ display: 'flex', flexDirection: 'column' }}>
                                {pt('Localization_2')}
                                {/* <span className="num">1</span>한국어 &gt; 도착언어 직번역 */}
                            </dt>
                            <dd>
                                {pt('Localization_3')}
                                {/* 중간 언어를 거치지 않고 한국어(출발언어)에서 도착언어로
                                직번역합니다. 한국어 문구의 의미와 톤을 잃지 않고 현지화되어
                                퀄리티가 높아집니다.
                                <br />
                                불필요한 중간 번역이 없어, 보다 빠르면서도 비용효율이 높습니다. */}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Localization_4')}
                                {/* <span className="num">2</span> 인하우스 원어민 번역가 */}
                            </dt>
                            <dd>
                                {pt('Localization_5')}
                                {/* 원어민이 초기에 번역 계단계부터 최종 검수까지 모든 단계를
                                작업합니다. 해당 언어와 문화에 대한 배경지식과 이해를 바탕으로,
                                게임 이용자에게 어색한 표현 없이 자연스러운 현지화 결과물을
                                서비스 합니다. */}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('Localization_6')}
                                {/* <span className="num">3</span> 게임에 대한 열정과 노하우 */}
                            </dt>
                            <dd>
                                {pt('Localization_7')}
                                {/* 글로벌 게임 개발사 경험 및 번역 경력의 프로젝트 매니저가 각
                                프로젝트에 대한 오너십을 갖고 고객사와 실시간으로 소통합니다.
                                <br />
                                게임에 대한 열정과 경험을 기반한 노하우로 프로젝트를 리딩합니다. */}
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>

            <section className="section_player">
                <div className="h_title_box inner">
                    <h3 className="h_title">
                        {pt('Localization_8')}
                        {/* 원어민 번역가 '내가 생각하는 로컬라이제이션은...' */}
                    </h3>
                    <p className="desc">
                        {pt('Localization_9')}
                        {/* 프리랜서가 아닌 원어민이 영어 중간 번역 과정 없이 한국어&gt;16개국
                        언어로 직접 번역합니다. */}
                    </p>
                </div>

                <div className="player">
                    <a
                        href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=92s"
                        data-href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=92s"
                        className="open_modal"
                    >
                        <span
                            className="thumb hidden_sm"
                            style={{
                                backgroundImage: 'url(/static/img/why2bytes/mv_thumb_ll.jpg)'
                            }}
                        />
                        <span
                            className="thumb visible_sm"
                            style={{
                                backgroundImage: 'url(/static/img/why2bytes/mv_thumb_ll_sm.png)'
                            }}
                        />
                    </a>
                </div>

                <div className="text_box">
                    <div className="inner d_table">
                        <div className="d_cell text_primary">
                            {pt('Localization_10')}
                            {/* 개발사의 게임이 글로벌 마켓에서 성공할 수 있도록
                            <br />
                            최상의 로컬라이제이션 서비스를 제공합니다. */}
                        </div>
                        <div className="d_cell btn_area">
                            <a
                                href={`/${i18n.language}/Contact`}
                                className="btn_basic btn_primary btn_xl btn_pop_open"
                            >
                                {pt('Localization_11')}
                                {/* 문의/업무의뢰 */}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            <span className="hidden_sm">
                                {pt('Localization_12')}
                                {/* 2bytes의 로컬라이제이션 - 글로벌콘텐츠팀 */}
                            </span>
                            <span className="visibl
                            {pt('Localization_13')}e_sm">
                                {/* 2bytes의 로컬라이제이션 <br />
                                글로벌콘텐츠팀 */}
                            </span>
                        </h3>
                        <p className="desc">
                            {pt('Localization_14')}
                            {/* PM, 번역가, 엔지니어가 한 팀으로 일하며 시너지 효과를 극대화
                            합니다.
                            <br />
                            국내에서 번역하고 해외 프리랜서가 감수만 진행하는 공정형 시스템이
                            아닙니다.
                            <br />
                            프로젝트별로 처음부터 끝까지 모든 작업 단계를 전문가가 직접
                            책임집니다. */}
                        </p>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            <span className="hidden_sm">
                                {pt('Localization_15')}
                                {/* 2bytes의 로컬라이제이션 - 서비스 제공언어 */}
                            </span>
                            <span className="visible_sm">
                                {pt('Localization_16')}
                                {/* 2bytes의 로컬라이제이션 <br />
                                서비스 제공언어 */}
                            </span>
                        </h3>
                        <p className="desc">
                            {pt('Localization_17')}
                            {/* 출발언어에서 중간 번역을 거치지 않고 도착언어로 다이렉트 현지화
                            합니다. <br />
                            개발언어 : 한국어 &gt; 16개 언어 */}
                        </p>
                    </div>

                    <div className="cont">
                        <table className="table_type">
                            <colgroup>
                                <col style={{ width: '50%' }} />
                                <col style={{ width: '50%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>

                                        {pt('Localization_18')}
                                        {/* 출발언어 */}
                                    </th>
                                    <th>

                                        {pt('Localization_19')}
                                        {/* 도착언어 */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {pt('Localization_20')}
                                        {/* 한국어 */}
                                    </td>
                                    <td>
                                        {pt('Localization_21')}
                                        {/* 영어, 일본어, 중국어 (간체), 중국어 (번체), 태국어,
                                        베트남어, 인도네시아어, 독일어, 프랑스어, 스페인어,
                                        포르투갈어, 러시아어, 폴란드어, 핀란드어, 스웨덴어, 터키어 */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="h_title_box">
                        <p className="desc">
                            {pt('Localization_22')}
                            {/* 개발언어 : 영어출발 &gt; 10개 언어 */}
                        </p>
                    </div>

                    <div className="cont">
                        <table className="table_type">
                            <colgroup>
                                <col style={{ width: '50%' }} />
                                <col style={{ width: '50%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        {pt('Localization_23')}
                                        {/* 출발언어 */}
                                    </th>
                                    <th>
                                        {pt('Localization_24')}
                                        {/* 도착언어 */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {pt('Localization_25')}
                                        {/* 영어 */}
                                    </td>
                                    <td>
                                        {pt('Localization_26')}
                                        {/* 한국어, 인도네시아어, 독일어, 프랑스어, 스페인어,
                                        포르투갈어, 러시아어, 핀란드어, 스웨덴어, 폴란드어 */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="h_title_box">
                        <p className="desc">
                            {pt('Localization_27')}
                            {/* 기타 */}
                        </p>
                    </div>

                    <div className="cont">
                        <table className="table_type">
                            <colgroup>
                                <col style={{ width: '50%' }} />
                                <col style={{ width: '50%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        {pt('Localization_28')}
                                        {/* 출발언어 */}
                                    </th>
                                    <th>
                                        {pt('Localization_29')}
                                        {/* 도착언어 */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {pt('Localization_30')}
                                        {/* 중국어 (간체), 중국어 (번체), 일본어 */}
                                    </td>
                                    <td>
                                        {pt('Localization_31')}
                                        {/* 한국어 */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('Localization_32')}
                            {/* 로컬라이제이션 <br className="visible_sm" />
                            프로세스 */}
                        </h3>
                        <p className="desc">
                            {pt('Localization_33')}
                            {/* 요구사항을 정확하게 분석해, 높은 퀄리티의 로컬라이제이션 결과를
                            협의된 일정에 제공합니다. */}
                        </p>
                    </div>

                    <div className="cont">
                        <div className="progress_box">
                            <ul className="flex_box">
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_34')}
                                        {/* PM 협의 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_35')}
                                                {/* Analysis */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_36')}
                                                {/* 요구사항 분석 및 내부 리소스 확보 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_37')}
                                                {/* Scheduling */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_38')}
                                                {/* 작업 기간 산정 및 일정 협의 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_39')}
                                        {/* 사전 준비 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_40')}
                                                {/* Familiarization */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_41')}
                                                {/* 게임 러닝 &amp; 콘텐츠 친숙화 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_42')}
                                                {/* preparation */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_43')}
                                                {/* 게임에 적합한 용어 및 스타일 가이드 수립 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_44')}
                                                {/* Engineering */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_45')}
                                                {/* 인게임 스트링
                                                <br />
                                                번역 솔루션으로 변환 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_46')}
                                        {/* 현지화 작업 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong
                                                className="tit">
                                                {pt('Localization_47')}
                                                {/* Translation */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_48')}
                                                {/* 원어민 100% 1차 번역 진행 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_49')}
                                                {/* Editing &amp; Proofreading */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_50')}
                                                {/* 원어민 리뷰어가 언어, 문화, 게임 속성에 맞게 검수 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_51')}
                                                {/* QA */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_52')}
                                                {/* 용어 통일성, 오타, 오역 등 번역 결과 머신 QA */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_53')}
                                        {/* 최종 체크 / 납품 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_54')}
                                                {/* Post-Engineering */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_55')}
                                                {/* 요청 파일 형식으로 변환 및 누락 스트링 체크 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_56')}
                                                {/* Delivery */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_57')}
                                                {/* 고객 요구사항을 준수하는지 확인 후 납품 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('Localization_58')}
                            {/* 로컬라이제이션QA (LQA) <br className="visible_sm" />
                            프로세스 */}
                        </h3>
                        <p className="desc">
                            {pt('Localization_59')}
                            {/* 요구사항을 정확하게 분석해, 높은 퀄리티의 LQA 결과를 협의된 일정에
                            제공합니다. */}
                        </p>
                    </div>

                    <div className="cont">
                        <div className="progress_box">
                            <ul className="flex_box">
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_60')}
                                        {/* PM 협의 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_61')}
                                                {/* Analysis */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_62')}
                                                {/* 번역본 분석 후<br />
                                                LQA 진행 / 재번역 여부 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_63')}
                                                {/* Scheduling */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_64')}
                                                {/* 작업 기간 산정 및 일정 협의 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_65')}
                                                {/* Resource */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_66')}
                                                {/* 용어집, 스타일 가이드, 게임 빌드 등의
                                                <br />
                                                리소스 확보 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_67')}
                                        {/* 사전 준비 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_68')}
                                                {/* Familiarization */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_69')}
                                                {/* 게임 러닝 &amp; 콘텐츠 친숙화 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_70')}
                                                {/* Test Planning */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_71')}
                                                {/* 테스트 플랜 및 번역 이슈 취합/수정 플로우 수립 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_72')}
                                                {/* Engineering */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_73')}
                                                {/* 인게임 스트링
                                                <br />
                                                번역 솔루션으로 변환 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_74')}
                                        {/* 현지화 작업 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_75')}
                                                {/* Build LQA */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_76')}
                                                {/* 빌드 기반의 UI/UX, 시각적 검수 작업 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_77')}
                                                {/* Loc QA */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_78')}
                                                {/* 게임 텍스트의 의도된 기능 및 플로우에 맞게 번역되어
                                                있는지 확인 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_79')}
                                                {/* Culturalization */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_80')}
                                                {/* 해당 언어, 국가/언어권 정서와 맞게 문화적 요소를 검수 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('Localization_81')}
                                        {/* 최종 체크 / 납품 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_82')}
                                                {/* Post-Engineering */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_83')}
                                                {/* 요청 파일 형식으로 변환 및<br />
                                                누락 스트링 체크 */}
                                            </p>
                                        </div>
                                        <div className="prog_con">
                                            <strong className="tit">
                                                {pt('Localization_84')}
                                                {/* Delivery */}
                                            </strong>
                                            <p className="con">
                                                {pt('Localization_85')}
                                                {/* 고객 요구사항을 준수하는지 확인 후 납품 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function Localization(props) {
    return (
        <div id="container" className="cont_ll">
            <SectionTop title="Localization / LQA" />
            <Content />
            <Why2Bytes />
            <SectionBottom />
        </div>
    );
}

export default Localization;
