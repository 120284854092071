/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import SectionTop from './SectionTop';
import Blog from './Blog';
import Box from '@mui/material/Box';
import arrow_right from '../../../src/resources/images/arrow-right.svg';
import { css } from '@emotion/react';

import PreLoader from '../../layout/PreLoader';

import careers_welcome_1 from '../../resources/images/careers_welcome_1.png';
import careers_welcome_2 from '../../resources/images/careers_welcome_2.png';
import careers_welcome_3 from '../../resources/images/careers_welcome_3.png';

// console.log(careers_welcome_1);

const welfareUrl = `https://medium.com/2bytescorp/%ED%88%AC%EB%B0%94%EC%9D%B4%ED%8A%B8-%EB%B3%B5%EC%A7%80%EC%9D%98-%EB%AA%A8%EB%93%A0-%EA%B2%83-73eee441054b`;

function JobsStatusList({ notionData }) {
    const { i18n } = useTranslation();
    const to = useNavigate();
    // console.log(notionData.groups[0]);
    const list = notionData.groups.map(e => ({
        name: e.부서,
        num: e.공고.length,
        url: e.pathname,
    }));

    return <Box sx={css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        @media (max-width: 1320px) {
            grid-template-columns: repeat(3, 1fr);
            
        }
        @media (max-width: 991px) {
            grid-template-columns: repeat(2, 1fr);  
    
        }
    `}>
        {
            list.map((e) => {
                return <Box key={e.url}
                    sx={css`
                        cursor: pointer;
                        background-color: #FFF;
                        display: flex;
                        flex-direction: row;
                        border: 1px solid #FFF;
                        font-size: 1.7rem;
                        font-weight: 500;
                        padding: 28px;
                        align-items : center;
                        justify-content: space-between;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                        color: #444;
                        &:hover {
                            border: 1px solid #743FA3;
                            background-color: #fff;
                            box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
                            transform: translateY(-5px);
                            transition: transform 0.2s ease 0s;
                            
                        }
                        &:hover .arrow_right_icon{
                            filter:none;
                        }
                        @media (max-width: 1320px) {
                            padding: 2.8rem;
                        }
                        @media (max-width: 991px) {
                            height: 58px;
                            padding: 1.3rem;
                            font-size: 1.2rem;
                        }
                   `}
                    onClick={() => {
                        to(`/${i18n.language}/Careers/Jobs/${e.url}`);
                    }}
                >
                    <Box >
                        {e.name}
                        <Box component="span" sx={{ marginLeft: '0.5rem', color: e.num === 0 ? 'rgba(0, 0, 0, 0.4)' : '#743FA3' }}>{e.num}</Box>
                    </Box>
                    <Box className="arrow_right_icon" sx={css`display: flex;filter:brightness(7.2) invert(1);`}>
                        <img src={arrow_right} css={css`
                            ::selection {
                                color: transparent;
                                background-color: transparent;
                            }
                        `} />
                    </Box>
                </Box>;
            })
        }
    </Box>;
}

function Section1() {
    const title = "투바이트스러운 분을 환영합니다";
    const items = [
        {
            id: '1',
            title: '선의로 서로를 존중하며',
            desc: `#상호존중 #수평커뮤니케이션`,
            icon: careers_welcome_1,
        },
        {
            id: '2',
            title: '어떤 일이든 투지를 갖고',
            desc: `#긍정 #도전 #혁신`,
            icon: careers_welcome_2,
        },
        {
            id: '3',
            title: '탁월하게 실행하는 사람',
            desc: `#책임감 #탁월함 #추진력`,
            icon: careers_welcome_3,
        },
    ];
    return <section className="careers section_discript">
        <div className="inner">
            <Box component="strong" sx={css`
                display: block;
                margin-bottom: 3rem;
                color: #743FA3;
                line-height: 140%;
                letter-spacing: -0.5px;
                font-weight: 700;
                font-size: 3.6rem;
                
                @media (max-width: 991px) {
                    font-size: 2.4rem;
                }
            `}>
                {title}
            </Box>

            <Box sx={css`
                text-align: center;
                display: flex;
                margin-top: 4rem;
                gap: 5.7rem;
                @media (max-width: 1320px) {
                    padding: 2.8rem;
                    margin-bottom: 16rem;
                }
                @media (max-width: 991px) {
                    flex-direction: column;
                    padding: 0;
                    font-size: 1.2rem;
                    gap: 2rem;
                    margin-top: 2.4rem;
                    margin-bottom: 8.4rem;
                }
            `}>
                {
                    items?.map(item => <Box component="dl" key={item.id} sx={css`
                        text-align: center;
                        width: 33%;
                        flex-grow: 1;
                        @media (max-width: 992px) {
                            width: 100%;
                        } 
                    `}>
                        <div css={css`
                            height: 30rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @media (max-width: 992px) {
                                height: auto;
                             } 
                        `}>
                            <img src={item.icon} css={css`
                            width: -webkit-fill-available;
                            width: 100%;
                            max-width: 300px;
                        `} />
                        </div>
                        {/* 3 box */}
                        <dt css={css`
                            font-weight: 700;
                            margin-bottom: 1.75rem;
                            font-size: 2.8rem;
                            line-height: 1.4;
                            @media (max-width: 992px) {
                                font-size: 2.6rem;
                            }   
                            @media (max-width: 400px) {
                                font-size: 2rem;
                            }
                        `}>
                            {item.title}
                        </dt>
                        <dd css={css`
                            font-size: 1.8rem;
                            line-height: 1.8;
                            @media (max-width: 1320px) {
                                font-size: 1.8rem;
                            }  
                            @media (max-width: 400px) {
                                font-size: 1.6rem;
                            }
                        `}>
                            {item.desc}
                        </dd>
                    </Box>)
                }
            </Box>
        </div>
    </section>;
}

function Section2() {
    return <Section
        title="투바이트의 복지를 알아보세요"
        items={[
            {
                id: '1',
                title: '투바이트 X 건강',
                desc: `체력 단련 및 오락 시설 구비
장기 근속자를 위한 휴가 선물
매년 건강검진 지원과 전문 의료기관 할인`
            },
            {
                id: '2',
                title: '투바이트 X 집중',
                desc: `120도 책상과 풀옵션 의자 & 듀얼암과 듀얼모니터 기본 제공 \n
업무 집중과 환기를 위한 카페형 좌석 구비 \n
외국인 한국 취업 비자 지원`
            },
            {
                id: '3',
                title: '투바이트 X 만족',
                desc: `투바이트만의 카페테리아
명절 및 생일 축하금 10만원 지급
특별한 날에 드리는 특별한 반차`
            },
        ]}
    />;
}


function Section(props) {
    return <section className="careers section_discript">
        <div className="inner">
            <Box component="strong" sx={css`
                display: block;
                margin-bottom: 3rem;
                color: #743FA3;
                line-height: 140%;
                letter-spacing: -0.5px;
                font-weight: 700;
                font-size: 3.6rem;
                
                @media (max-width: 991px) {
                    font-size: 2.4rem;
                }
            `}>
                {props.title}
            </Box>

            <Box sx={css`
                text-align: center;
                display: flex;
                margin-top: 4rem;
                gap: 5.7rem;
                @media (max-width: 1320px) {
                    padding: 2.8rem;
                }
                @media (max-width: 991px) {
                    flex-direction: column;
                    padding: 0;
                    font-size: 1.2rem;
                    gap: 2rem;
                    margin-top: 2.4rem;
                }
            `}>
                {
                    props.items?.map(item => <Box component="dl" key={item.id} sx={css`
                        text-align: center;
                        padding: 32px;
                        width: 100%;
                        background-color: #FFF;
                    `}>
                        <div className={'careers_ico_' + item.id} />
                        {/* 3 box */}
                        <dt css={css`
                            font-weight: 700;
                            margin-bottom: 1.75rem;
                            font-size: 2.8rem;
                            line-height: 1.4;
                            @media (max-width: 992px) {
                                font-size: 2.6rem;
                            }   
                            @media (max-width: 400px) {
                                font-size: 2rem;
                            }
                        `}>
                            {item.title}
                        </dt>
                        <dd css={css`
                            white-space: break-spaces;
                            font-size: 1.8rem;
                            line-height: 1.6;
                            @media (max-width: 1320px) {
                                font-size: 1.8rem;
                                line-height: 3rem;
                            }  
                            @media (max-width: 400px) {
                                font-size: 1.6rem;
                                line-height: 2.5rem;
                            }
                        `}>
                            {
                                item.desc.split(/\n/).map(str => <div key={str} style={{ marginBottom: '0.6rem' }}>{str}</div>)
                            }
                        </dd>
                    </Box>)
                }
            </Box>
        </div>
    </section>;
}


function Content({ notionData }) {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);
    return (
        <>
            <section className="careers section_discript">
                <div className="inner">
                    <strong css={css`
                        display: block;
                        margin-bottom: 4rem;
                        font-size: 5rem;
                        line-height: 1.28;
                        letter-spacing: -.2rem;
                        font-weight: 500;
                        @media (max-width: 991px) {
                            font-size: 3rem;
                        }
                    `}>
                        투바이트의 여정에 합류하세요!
                        {/* <span className="hidden_sm">
                            투바이트의 여정에 합류하세요
                        </span>
                        <span className="visible_sm">
                            투바이트의 여정에<br />
                            합류하세요
                        </span> */}
                    </strong>
                    <div className="desc">
                        <JobsStatusList {...{ notionData }} />
                    </div>
                </div>
            </section>


            <Section1 />
            <Section2 />

            <section className="careers_welfare_check" >
                <div className="text_box">
                    <div className="inner d_table" >
                        <div className="d_cell text_primary" >
                            이건 투바이트의 많은 복지 중 일부일 뿐!<br className="visible_md" /> 더 자세한 내용을 확인하고 싶으시다면...
                        </div>
                        <div className="d_cell btn_area" >
                            <a
                                href={welfareUrl}
                                target="_blank"
                                className="btn_basic btn_primary btn_xl btn_pop_open white_space_nowrap"
                            >
                                전체 복지 확인하기
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="careers section_discript">
                <div className="inner">
                    <strong className="title">
                        투바이트의 분위기를 미리 맛보세요
                    </strong>

                    <div className="step_box">
                        <dl>
                            <div className='careers_ico_1' />
                            <dt>
                                투바이트의 기업문화가 궁금하세요?
                            </dt>
                            <dd style={{ whiteSpace: 'pre-line!important' }}>
                                스마트하게 일하기 위한 자유로운 문화,
                                투바이트가 만듭니다.
                            </dd>
                        </dl>
                        <dl>
                            <div className='careers_ico_2' />
                            <dt>
                                [연말행사] 투바이트가 일하는 곳을 공개합니다.
                            </dt>
                            <dd>
                                최근 투바이트의 둥지를 확장했습니다.
                            </dd>
                        </dl>
                        <dl>
                            <div className='careers_ico_3' />
                            <dt>
                                연봉 협상 후 받은 *CEO 편지*
                            </dt>
                            <dd>
                                투바이트의 연봉 협상을 마치며 회고해보는 ‘나의, 나에 의한, 나를 위한 투자’
                            </dd>
                        </dl>
                    </div>
                </div>
            </section> */}

            <Blog {...{ notionData }} />

            <section className="section_bottom">
                <div className="inner d_table">
                    <div className="d_cell text_white" style={{ fontWeight: 400 }}>
                        투바이트와 함께 할 준비가 되셨나요?
                        <br />
                        지금 바로 지원하세요!
                    </div>
                    <div className="d_cell btn_area">
                        <Link component="a"
                            to={`/${i18n.language}/Careers/Jobs`}
                            className="btn_basic btn_xl btn_pop_open">
                            채용 공고 확인하기
                        </Link>
                        {/* <a
                            href={welfareUrl}
                            target="_blank"
                            className="btn_basic btn_xl btn_pop_open">
                            채용 공고 확인하기
                        </a> */}
                    </div>
                </div>
            </section>

        </>
    );
}

function Life({ notionData }) {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const _location = useLocation();
    const title = _location.pathname.match(/\bCareers\/(.+)\/?/)[1];

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    
    return <div id='container'>
        <PreLoader {...{ notionData }} ></PreLoader>
        <SectionTop title={title} />
        <Content {...{ notionData }} />
    </div>;
}

export default Life;