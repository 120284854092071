/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Box } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import ill_cm from '../resources/images/ill_cm.png?url';


export default function Why2Bytes() {

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        $('.open_modal').YouTubePopUp();
    }, []);


    const pageLink = (page) => {
        const currentPath = pathname.trim().split('/')[3];
        console.log(page, currentPath);
        if (page === currentPath) {
            $(window).scrollTop(0);
        } else if (page === "Localization") {
            navigate(`/${i18n.language}/Services/Localization`);
        }
        else if (page === "QA") {
            navigate(`/${i18n.language}/Services/QA`);
        }
        else if (page === "PlayerSupport") {
            navigate(`/${i18n.language}/Services/PlayerSupport`);
        }
        else if (page === "ContentMarketing") {
            navigate(`/${i18n.language}/Services/ContentMarketing`);
        }
    };


    return (
        <section className="section_why">
            <div className="inner">
                <div className="h_title_box">
                    <h3 className="h_title">
                        {pt('Why2Bytes_1')}
                        {/* Why 2bytes? */}
                    </h3>
                </div>

                <div className="cont_box_wrap clear">
                    <div className="box col2 col1_sm ll">
                        <div className="box_in">
                            <p className="heading">
                                {pt('Why2Bytes_2')}
                                {/* Localization / LQA */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_3')}
                                </strong>
                                <div className="desc">
                                    <p>
                                        {pt('Why2Bytes_4')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("Localization")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>

                                <a
                                    href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=92s&autoplay=1&mute=1"
                                    data-href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=92s&autoplay=1&mute=1"
                                    className="btn_expert_video open_modal"
                                    style={{
                                        maxWidth: '55rem',
                                    }}
                                >
                                    <Box component="p" className="hidden_sm trans text_primary " sx={{
                                        fontSize: '3rem',
                                        marginRight: '-2.5rem',
                                        whiteSpace: 'nowrap',
                                    }}>
                                        {pt('Why2Bytes_6')}
                                        {/* <strong className="ko">한글</strong><strong className="num">20<em className="plus">+</em></strong>언어 */}
                                    </Box>
                                    <Box component="p" className="hidden_sm talk" sx={{
                                        marginRight: '-2.5rem',
                                        '@media (max-width:1280px)': {
                                            '& .in_block.text_primary':{
                                                marginRight: '-1.4rem!important',
                                                width: '20rem!important',
                                                letterSpacing: '-0.09rem!important',
                                            }
                                        }
                                    }}>
                                        {pt('Why2Bytes_7')}
                                        {/* <span className="in_block text_primary"></span>
                                        <strong className="in_block text_primary">Talk</strong> */}
                                    </Box>
                                    <span className="visible_sm">
                                        {pt('Why2Bytes_8')}
                                        {/* 전문가영상 */}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="box col2 col1_sm qa">
                        <div className="box_in">
                            <p className="heading">
                                {pt('Why2Bytes_9')}
                                {/* Quality Assurance */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_10')}
                                    {/* 게임의 품질을 위한 열정 */}
                                </strong>
                                <div className="desc">
                                    <p>
                                        {pt('Why2Bytes_11')}
                                        {/* 투바이트 QA 전문가들은 창의적이고 도전적이며 집요하고,
                                        <br className="hidden_sm" />
                                        어떠한 상황에서도 민첩하게 대응합니다.
                                        <br />
                                        대작 게임들의 QA를 성공적으로 진행했던 경험을 토대로{' '}
                                        <br className="hidden_sm" />
                                        제품의 전반적인 품질과 라이브 서비스에 영향을 미칠 위험을{' '}
                                        <br className="hidden_sm" />
                                        미리 식별하여 최고의 성과를 낼 수 있도록 도와드리겠습니다. */}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("QA")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                                <a
                                    href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=322s&autoplay=1&mute=1"
                                    data-href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=322s&autoplay=1&mute=1"
                                    className="btn_expert_video open_modal"
                                >
                                    <Box className="hidden_sm talk" sx={{
                                       whiteSpace: 'nowrap'
                                    }}>
                                        {pt('Why2Bytes_13')}
                                        {/* <span className="text_primary">박준수</span>
                                        <strong className="text_primary">Talk</strong> */}
                                    </Box>
                                    <span className="visible_sm">
                                        {pt('Why2Bytes_8')}
                                        {/* 전문가영상 */}
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="box col2 col1_sm ps">
                        <div className="box_in">
                            <p className="heading">
                                {pt('Why2Bytes_14')}
                                {/* Player Support */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_15')}
                                    {/* 언어 특성을 고려한 <br className="visible_sm" />
                                    맞춤형 케어 */}
                                </strong>
                                <div className="desc">
                                    <p>
                                        {pt('Why2Bytes_16')}
                                        {/* 게임은 런칭 후부터 진정한 시작이죠.
                                        <br />
                                        투바이트에서는 각국 언어의 CM/CS 서비스를 제공하며{' '}
                                        <br className="hidden_sm" />
                                        이를 통해 전 세계 플레이어에게 더욱 가깝게 다가설 수
                                        있습니다. <br />
                                        PC/ 모바일 등 다년 경력을 보유한 실무진의 경험과 노하우를{' '}
                                        <br className="hidden_sm" />
                                        바탕으로 각 언어 전문가가 언어 특성에 맞게 케어합니다. */}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("PlayerSupport")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                                <div className="btn_expert_video open_modal">
                                    <p className="hidden_sm talk">
                                        {pt('Why2Bytes_17')}
                                        {/* <span className="text_primary">Kamiyama Jurina</span> */}
                                    </p>
                                    <span className="visible_sm">
                                        {pt('Why2Bytes_8')}
                                        {/* 전문가영상 */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box col2 col1_sm cm">
                        <div className="box_in">
                            <p className="heading">
                                {pt('Footer_15')}
                                {/* Quality Assurance */}
                            </p>

                            <div className="cont">
                                <strong className="title">
                                    {pt('Why2Bytes_18')}
                                    {/* Content Marketing */}
                                </strong>
                                <div className="desc">
                                    <p>
                                        {pt('Why2Bytes_19')}
                                    </p>
                                </div>
                            </div>

                            <div className="manager_talk">
                                <button onClick={() => pageLink("ContentMarketing")} className="btn_basic">
                                    {pt('Why2Bytes_5')}
                                    {/* 자세히 보기 */}
                                </button>
                                <div
                                    css={css`
                                        position: absolute;
                                        right: 1rem;
                                        bottom: 0;
                                        padding: 6rem 16rem 3.2rem 5rem;
                                        text-align: right;
                                        &::after{
                                            content: "";
                                            display: block;
                                            position: absolute;
                                            right: 0;
                                            bottom: 0;
                                            width: 16.5rem;
                                            height: 18.4rem;
                                            background-repeat: no-repeat;
                                            background-position: center bottom;
                                            background-image: url(${ill_cm});
                                        }
                                        @media (max-width:991px) {
                                            display: none;
                                        }
                                    `}
                                >
                                    {/* 전예준 */}
                                    <p className="hidden_sm talk">
                                        {pt('Why2Bytes_20')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
