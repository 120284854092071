import React, { useEffect } from 'react';
import SectionTop from '../layout/SectionTop';
import Why2Bytes from '../layout/Why2Bytes';
import SectionBottom from '../layout/SectionBottom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Content() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);
    return (
        <>
            <section className="section_discript">
                <div className="inner">
                    <strong className="title">
                        {pt('PlayerSupport_1')}
                        {/* 글로벌 이용자 니즈를 <br className="visible_sm" />
                        충족하는 최상의 CS/CM 서비스 */}
                    </strong>

                    <div className="dl_box">
                        <dl>
                            <dt>
                                {pt('PlayerSupport_2')}
                                {/* 글로벌 CS */}
                            </dt>
                            <dd>
                                {pt('PlayerSupport_3')}
                                {/* 다국어 이용자 문의에 응대 가능 (Multi-language support)합니다.
                                <br />
                                글로벌 대규모 서비스를 경험한 실무진이 운영을 리딩합니다. */}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('PlayerSupport_4')}
                                {/* 글로벌 CM */}
                            </dt>
                            <dd>
                                {pt('PlayerSupport_5')}
                                {/* 각 지역의 언어/문화 특성에 기반해, 개발사와 원활한
                                커뮤니케이션으로 유저와의 소통을 돕습니다.
                                <br />
                                365/24 서비스되는 게임 서비스 환경에 맞춰 한국어 기반의 긴급
                                대응을 제공합니다. */}
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>

            <section className="section_player">
                <div className="h_title_box inner">
                    <h3 className="h_title">
                        {pt('PlayerSupport_6')}
                        {/* 글로벌 PS 담당 쥬리나님의 <br className="visible_sm" />
                        "2bytes의 PS는요." */}
                    </h3>
                    <p className="desc">
                        {pt('PlayerSupport_7')}
                        {/* "글로벌 이용자의 CS 핵심은 -편의" , <br className="visible_sm" />
                        "편의는 이용자의 문제 해결" , <br className="visible_sm" />
                        "모국어로 질문/답변이 가장 빠르고 정확" */}
                    </p>
                </div>

                <div className="player">

                </div>

                <div className="text_box">
                    <div className="inner d_table">
                        <div className="d_cell text_primary">
                            {pt('PlayerSupport_8')}
                            {/* 이용자는 모국어로 질문하고, <br className="visible_sm" />
                            모국어로 답변받는 것이 자연스럽습니다.
                            <br />
                            게임 유저가 겪는 문제를 빠르고 정확하게 해결하는 솔루션
                            <br className="hidden_sm" />
                            2bytes 글로벌 CS/CM 입니다. */}
                        </div>
                        <div className="d_cell btn_area">
                            <a
                                href={`/${i18n.language}/Contact`}
                                className="btn_basic btn_primary btn_xl btn_pop_open"
                            >
                                {pt('PlayerSupport_9')}
                                {/* 문의/업무의뢰 */}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('PlayerSupport_10')}
                            {/* 2bytes의 글로벌 PS의 효율성 */}
                        </h3>
                        <p className="desc">
                            {pt('PlayerSupport_11')}
                            {/* 다른언어, 다른문화, 다른 시간대 - 각 언어별 대응하는 운영은
                            비효율적입니다.
                            <br />
                            "일관성이 유지되는 운영과 이용자 요청에 빠른 피드백"
                            <br />
                            글로벌 유저의 만족도가 높아집니다. */}
                        </p>
                    </div>

                    <div className="cont">
                        <div className="ps_table flex_box">
                            <div className="item">
                                <span className="img hidden_sm">
                                    <img src={t('url_img_ps_table_01')} alt="" />
                                </span>
                                <span className="img visible_sm">
                                    <img src={t('url_img_ps_table_01')} alt="" />
                                </span>
                                <p className="text">
                                    {pt('PlayerSupport_12')}
                                    {/* 다른 언어, 다른 문화, 다른 시간대
                                    <br />각 언어별 대응 운영은 비효율적입니다. */}
                                </p>
                            </div>
                            <div className="item">
                                <span className="img hidden_sm">
                                    <img src={t('url_img_ps_table_02')} alt="" />
                                </span>
                                <span className="img visible_sm">
                                    <img src={t('url_img_ps_table_02')} alt="" />
                                </span>
                                <p className="text">
                                    {pt('PlayerSupport_13')}
                                    {/* 다른 언어, 다른 문화, 다른 시간대
                                    <br />각 언어별 대응 운영은 비효율적입니다. */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function PlayerSupport(props) {
    return (
        <div id="container" className="cont_ps">
            <SectionTop title="Player Support" mtitle="PS" />
            <Content />
            <Why2Bytes />
            <SectionBottom />
        </div>
    );
}

export default PlayerSupport;
