import React, { useEffect } from 'react';
import SectionTop from '../layout/SectionTop';
import Why2Bytes from '../layout/Why2Bytes';
import SectionBottom from '../layout/SectionBottom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function Content(props) {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);
    return (
        <>
            <section className="section_discript">
                <div className="inner">
                    <strong className="title">
                        {pt('QA_1')}
                        {/* 2bytes QA의 핵심 <br className="visible_sm" />역량 3가지 */}
                    </strong>

                    <div className="step_box">
                        <dl>
                            <dt>
                                {pt('QA_2')}
                                {/* <span className="num">1</span> 대표 IT 기업 출신의 QA 전문가 */}
                            </dt>
                            <dd>
                                {pt('QA_3')}
                                {/* 검은사막, 리니지2레볼루션, 라그나로크 등 AAA급 MMORPG QA 경험을
                                보유한 실무진이 직접 QA를 전담합니다. 대형개발사의 노하우 그대로
                                최적의 QA PLAN을 제안합니다. */}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('QA_4')}
                                {/* <span className="num">2</span> 다양한 플랫폼에서의 경험 */}
                            </dt>
                            <dd>
                                {pt('QA_5')}
                                {/* Mobile 뿐만 아니라 PC, Web 기반 게임까지 다양한 플랫폼의 QA를
                                제공합니다. <br />
                                17년 경력의 QA 전문가가 Plan 감수 및 진행을 리드합니다. */}
                            </dd>
                        </dl>
                        <dl>
                            <dt>
                                {pt('QA_6')}
                                {/* <span className="num">3</span> 마켓별 정책 대응 및 피쳐드 전략 */}
                            </dt>
                            <dd>
                                {pt('QA_7')}
                                {/* Google, Apple 등 주요 마켓 검수를 위한 최적화 방안과 Featured
                                Know-How를 제공합니다. */}
                            </dd>
                        </dl>
                    </div>
                </div>
            </section>

            <section className="section_player">
                <div className="h_title_box inner">
                    <h3 className="h_title">
                        {pt('QA_8')}
                        {/* QA팀 리더가 얘기하는 2bytes <br className="visible_sm" />최적화 플랜 */}
                    </h3>
                    <p className="desc">
                        {pt('QA_9')}
                        {/* 2bytes는 게임별/개발상황별로 유연한 QA를 제공합니다. 영상으로
                        확인해보세요. */}
                    </p>
                </div>

                <div className="player">
                    <a
                        href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=322s"
                        data-href="https://www.youtube.com/watch?v=1jvCVbyMUEI&t=322s"
                        className="open_modal"
                        rel="noopener"
                    >
                        <span
                            className="thumb hidden_sm"
                            style={{
                                backgroundImage: 'url(/static/img/why2bytes/mv_thumb_qa.jpg)'
                            }}
                        ></span>
                        <span
                            className="thumb visible_sm"
                            style={{
                                backgroundImage: 'url(/static/img/why2bytes/mv_thumb_qa_sm.png)'
                            }}
                        ></span>
                    </a>
                </div>

                <div className="text_box">
                    <div className="inner d_table">
                        <div className="d_cell text_primary">
                            {pt('QA_10')}
                            {/* 게임시장 성숙도에 따라, 유저가 바라는 서비스의 품질 또한
                            높아졌습니다.
                            <br />
                            10여종 이상의 Full QA를 개발사에서 모두 진행하는 것은
                            비효율적입니다.
                            <br />
                            2bytes QA팀과 최적의 QA PLAN을 확인해보세요. */}
                        </div>
                        <div className="d_cell btn_area">
                            <a
                                href={`/${i18n.language}/Contact`}
                                className="btn_basic btn_primary btn_xl btn_pop_open"
                            >
                                {pt('QA_11')}
                                {/* 문의/업무의뢰 */}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('QA_12')}
                            {/* 2bytes의 QA */}
                        </h3>
                        <p className="desc">
                            {pt('QA_13')}
                            {/* 개발사 개발방법론 및 게임 특징에 따라 - 최적의 QA Plan을
                            제안드립니다.
                            <br />
                            TQA, FQA, FGT, 사용성, 대규모 단체 테스트/서버 부하 테스트 등도
                            선택할 수 있습니다. */}
                        </p>
                    </div>

                    <div className="cont">
                        <table className="table_type">
                            <colgroup>
                                <col style={{ width: '58%' }} />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>
                                        {pt('QA_14')}
                                        {/* Software Testing */}
                                    </th>
                                    <th>
                                        {pt('QA_50')}
                                        {/* Market */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="v_align_top">
                                        <div className="testing_list">
                                            <ul>
                                                <li>
                                                    {pt('QA_15')}
                                                    {/* Sanity Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_16')}
                                                    {/* Functional Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_17')}
                                                    {/* Usability Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_18')}
                                                    {/* Function Interruption Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_19')}
                                                    {/* Mobile PvP Latency Test */}
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                    {pt('QA_20')}
                                                    {/* Compatibility Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_21')}
                                                    {/* Performance Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_22')}
                                                    {/* Mobile Network Test */}
                                                </li>
                                                <li>
                                                    {pt('QA_23')}
                                                    {/* App Coexistence Test */}
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td className="v_align_top">
                                        {pt('QA_24')}
                                        {/* 마켓 심사 Reject 분석 및 대응
                                        <br />
                                        Policy / Featured 검수
                                        <br />
                                        반려사항 DB 기반 분석 */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('QA_25')}
                            {/* 2bytes QA 진행 프로세스 */}
                        </h3>
                        <p className="desc">
                            {pt('QA_26')}
                            {/* 각 게임에 필요한 최적의 QA Plan과 QA 전략을 제시합니다. 게임의
                            완성도가 달라집니다. */}
                        </p>
                    </div>

                    <div className="cont">
                        <div className="progress_box sm">
                            <ul className="flex_box">
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_27')}
                                        {/* STEP 1 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_28')}
                                                {/* 요구사항 분석 및 제품 파악 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_29')}
                                        {/* STEP 2 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_30')}
                                                {/* 제품 분석을 통한 QA전략/PLAN 수립 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_31')}
                                        {/* STEP 3 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_32')}
                                                {/* 테스트 환경 및 프로세스 셋업 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_33')}
                                        {/* STEP 4 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_34')}
                                                {/* QA 진행 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_35')}
                                        {/* STEP 5 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_36')}
                                                {/* 서비스 적합도 판단 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <p className="prog_head">
                                        {pt('QA_37')}
                                        {/* STEP 6 */}
                                    </p>
                                    <div className="prog_body">
                                        <div className="prog_con">
                                            <p className="con">
                                                {pt('QA_38')}
                                                {/* QA Sign off */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section_contents">
                <div className="inner">
                    <div className="h_title_box">
                        <h3 className="h_title">
                            {pt('QA_39')}
                            {/* 2bytes QA진행 사례 */}
                        </h3>
                    </div>

                    <div className="cont">
                        <div className="list_items qa">
                            <ul>
                                <li className="col2">
                                    <div className="item_inner">
                                        <span className="thumb">
                                            <img src="/static/img/why2bytes/qa_01.png" alt="" />
                                        </span>
                                        <div className="info">
                                            <strong className="tit">
                                                {pt('QA_40')}
                                                {/* 데카론M */}
                                            </strong>
                                            <p className="desc">
                                                {pt('QA_41')}
                                                {/* 2012년 3월 ~ 4월(4주간) Full QA진행
                                                <br />
                                                4월 중순에 안정적 런칭 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="col2">
                                    <div className="item_inner">
                                        <span className="thumb">
                                            <img src="/static/img/why2bytes/qa_02.jpg" alt="" />
                                        </span>
                                        <div className="info">
                                            <strong className="tit">
                                                {pt('QA_42')}
                                                {/* 대형 게임개발 A사 QA */}
                                            </strong>
                                            <p className="desc">
                                                {pt('QA_43')}
                                                {/* 15개 분야, 총 1.1만개 테스트 case 수행 2021년 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="s_title_box">
                            <strong className="s_title">
                                {pt('QA_44')}
                                {/* 신작 FPS게임 대규모 FGT */}
                            </strong>
                            <a
                                href="https://medium.com/@2bytes_kr/%ED%88%AC%EB%B0%94%EC%9D%B4%ED%8A%B8-fgt-10%EC%9D%BC%EA%B0%84%EC%9D%98-%EA%B8%B0%EB%A1%9D-4268a34f2794"
                                className="btn_basic"
                            >
                                {pt('QA_45')}
                                {/* FGT:240시간의 기록 */}
                            </a>
                        </div>

                        <div className="list_items qa">
                            <ul className="clear">
                                <li className="col2">
                                    <div className="item_inner">
                                        <span className="thumb">
                                            <img src="/static/img/why2bytes/qa_03.png" alt="" />
                                        </span>
                                        <div className="info">
                                            <strong className="tit">
                                                {pt('QA_46')}
                                                {/* FGT 진행 현장 */}
                                            </strong>
                                            <p className="desc">
                                                {pt('QA_47')}
                                                {/* 4가지 방식으로 50여명의 테스터가 게임을 진행 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <li className="col2">
                                    <div className="item_inner">
                                        <span className="thumb">
                                            <img src="/static/img/why2bytes/qa_04.png" alt="" />
                                        </span>
                                        <div className="info">
                                            <strong className="tit">
                                                {pt('QA_48')}
                                                {/* 그룹별 FGI 진행 현장 */}
                                            </strong>
                                            <p className="desc">
                                                {pt('QA_49')}
                                                {/* 테스트 뒤 그룹을 나눠 세부 인터뷰를 진행 */}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

function Qa(props) {
    return (
        <div id="container" className="cont_qa">
            <SectionTop title="Quality Assurance" mtitle="QA" />
            <Content />
            <Why2Bytes />
            <SectionBottom />
        </div>
    );
}

export default Qa;
