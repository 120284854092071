import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function SectionTop(props) {
    const { title } = props;

    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    return (
        <section className="section_top section_top_services">
            <div className="page_cover">
                <div className="inner">
                    <h2 className="cont_title"> {title === '4 Stages' && (
                        <>
                            {pt('Footer_4')}

                        </>
                    )}
                        {title === 'Localization / LQA' && (
                            <>
                                {pt('Footer_5')}

                            </>
                        )}
                        {title === 'Quality Assurance' && (
                            <>
                                {pt('Footer_6')}

                            </>
                        )}
                        {title === 'Player Support' && (
                            <>
                                {pt('Footer_7')}

                            </>
                        )}
                        {title === 'Content Marketing' && (
                            <>
                                {pt('Footer_15')}
                            </>
                        )}
                    </h2>
                    <p className="desc">
                        <span className='hidden_sm'>
                            {title === '4 Stages' && (
                                <>
                                    {pt('SectionTop_3')}
                                    {/* 아이디어에서 출발해 실 서비스할 때까지
                                    <br />
                                    게임 개발 과정 하나하나에 들어가는 정성과 수고로움을 잘
                                    이해합니다.
                                    <br />
                                    <strong>
                                        성공하는 글로벌 게임, 그 과정을 함께하는 파트너사로 기억되고
                                        싶습니다.
                                    </strong> */}
                                </>
                            )}
                            {title === 'Localization / LQA' && (
                                <>
                                    {pt('SectionTop_4')}
                                    {/* 언어와 문화의 장벽 없이 전세계 유저가 공감하는 콘텐츠를 위하여 <br />
                                    원어민 콘텐츠 전문가가 한 글자 한 글자 정성들여 검수합니다.<br />
                                    <strong>단순 번역이 아닌 현지화 완성도를 책임지겠습니다.</strong> */}
                                </>
                            )}
                            {title === 'Quality Assurance' && (
                                <>
                                    {pt('SectionTop_5')}
                                    {/* 투바이트 QA는 집요하고 치열하게 위험한 결함들을 발견하며<br />
                                    의도한 방향대로 제품이 만들어 질 수 있도록 끊임없이 탐구합니다.<br />
                                    <strong>게임의 품질관리 활동은 QA 전문가가 책임지겠습니다.</strong> */}
                                </>
                            )}
                            {title === 'Player Support' && (
                                <>
                                    {pt('SectionTop_6')}
                                    {/* 글로벌 유저들이 신뢰를 갖고 지속적으로 게임을 이용할 수 있도록<br />
                                    콘텐츠와 게임 산업의 이해가 높은 글로벌 운영자들이 함께 합니다.<br />
                                    <strong>빠르고 정확하게 소통하는 한국의 글로벌 서비스 기업 투바이트와 함께하세요.</strong> */}
                                </>
                            )}
                            {title === 'Content Marketing' && (
                                <>
                                    {pt('SectionTop_18')}
                                    {/* 콘텐츠 기획자, 제작자, 카피라이터, 영상/방송 PD, 디자이너, CM들이<br />
                                    브랜드의 ‘스토리’를 만들고 소비자에게 ‘텔링’하는데 최선을 다합니다. */}
                                </>
                            )}
                        </span>
                        <span className='visible_sm'>
                            {title === '4 Stages' && (
                                <>
                                    <strong>
                                        {pt('SectionTop_7')}
                                        {/* 성공하는 글로벌 게임,<br /> 그 과정을 함께하는 파트너사로 기억되고
                                        싶습니다. */}
                                    </strong>
                                </>
                            )}
                            {title === 'Localization / LQA' && (
                                <>

                                    <strong>
                                        {pt('SectionTop_8')}
                                        {/* 단순 번역이 아닌 <br />현지화 완성도를 책임지겠습니다. */}
                                    </strong>

                                </>
                            )}
                            {title === 'Quality Assurance' && (
                                <>
                                    <strong>
                                        {pt('SectionTop_9')}
                                        {/* 게임의 품질관리 활동은<br /> QA 전문가가 책임지겠습니다. */}
                                    </strong>
                                </>
                            )}
                            {title === 'Player Support' && (
                                <>
                                    <strong>
                                        {pt('SectionTop_10')}
                                        {/* 빠르고 정확하게 소통하는 한국의 글로벌 서비스 기업 <br />투바이트와 함께하세요. */}
                                    </strong>
                                </>
                            )}
                            {title === 'Content Marketing' && (
                                <>
                                    <strong>
                                        {pt('SectionTop_19')}
                                        {/* 2bytes Content Marketing의 핵심 역량 6가지 */}
                                    </strong>
                                </>
                            )}
                        </span>
                    </p>
                </div>
            </div>

            <div className="tab_wrap fixed_top">
                <div className="tab_box">
                    <ul className="tab_list">
                        <li className={title === 'Localization / LQA' ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Services/Localization`}>
                                <span className="thumb" />
                                <strong className="text">
                                    {pt('Footer_5')}
                                    {/* Localization / LQA */}
                                </strong>
                            </Link>
                        </li>
                        <li className={title === 'Quality Assurance' ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Services/QA`}>
                                <span className="thumb" />
                                <strong className="text hidden_sm">
                                    {pt('Footer_6')}
                                    {/* Quality Assurance */}
                                </strong>
                                <strong className="text visible_sm">
                                    {pt('SectionTop_15')}
                                    {/* QA */}
                                </strong>
                            </Link>
                        </li>
                        <li className={title === 'Player Support' ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Services/PlayerSupport`}>
                                <span className="thumb" />
                                <strong className="text hidden_sm">
                                    {pt('Footer_7')}
                                    {/* Player Support */}
                                </strong>
                                <strong className="text visible_sm">
                                    {pt('SectionTop_17')}
                                    {/* PS */}
                                </strong>

                            </Link>
                        </li>
                        <li className={title === 'Content Marketing' ? 'on' : ''}>
                            <Link to={`/${i18n.language}/Services/ContentMarketing`}  >
                                <span className="thumb" />
                                <strong className="text hidden_sm">
                                    {pt('Footer_15')}
                                    {/* Content Marketing */}
                                </strong>
                                <strong className="text visible_sm">
                                    {pt('SectionTop_20')}
                                    {/* CM */}
                                </strong>

                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
