import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';


function Content({ list }) {
    const { i18n } = useTranslation();

    return list.map((item, index) => <li key={index} className="col3 col1_sm">
        <a
            href={item.url}
            target="_blank"
            className="item_inner"
            rel="noreferrer"
        >
            <span className="thumb">
                <img src={item.img} alt="" />
            </span>
            <div className="info">
                <strong className="tit">{item.tit}</strong>
                <p className="desc">{item.desc}</p>
            </div>
        </a></li>);
}

export default function Blog(props) {
    const { t, i18n } = useTranslation();
    const [list, setList] = useState([]);

    useEffect(() => {
        const defaultUrl = `/static/img/blog/list-en.json`;
        const url = i18n.language === "ko" ? `/static/img/blog/list-${i18n.language}.json` : defaultUrl;

        axios.get(url).then(s => s.status === 200 && s.data).then(list => {
            if (Array.isArray(list) && list.length) {
                setList(list.reverse().slice(0, 3));
            } else {
                setList([]);
            }
        }).catch((err) => {
            console.log(err.message);
            axios.get(defaultUrl).then(s => s.status === 200 && s.data).catch((err) => {
                console.log(err.message);
                setList([]);
            }).then(list => {
                if (Array.isArray(list) && list.length) {
                    setList(list.reverse().slice(0, 3));
                } else {
                    setList([]);
                }
            });
        });

    }, [i18n.language]);

    return <section className="section_blog" style={{ display: list.length === 0 ? 'none' : '' }}>
        <div className="inner">
            <div className="h_title_box">
                <h3 className="h_title text_white">
                    {t('Home_6')}
                    {/* 2bytes Blog */}
                </h3>
            </div>

            <div className="blog_items">
                <ul className="clear">
                    <Content list={list} />
                </ul>
            </div>
        </div>
    </section>;
};


