import React, {
    useState,
    useCallback,
    forwardRef,
    useImperativeHandle,
    useEffect
} from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';


axios.default.paramsSerializer = params => {
    return qs.stringify(params);
};

// console.log("---------------");
// console.log(process.env.REACT_APP_SLACK_URL);
// console.log("---------------");


const ContactLayer = forwardRef((props, ref) => {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(t(v));

    const { openContactAlertLayer, _ } = props;

    const [display, setDisplay] = useState(false);

    const [company, setCompany] = useState('');
    const [tel, setTel] = useState('');
    const [name, setName] = useState('');
    const [duty, setDuty] = useState('');
    const [email, setEmail] = useState('');
    const [privacy, setPrivacy] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [valid, setValid] = useState({
        company: false,
        tel: false,
        name: false,
        duty: false,
        email: false,
        privacy: false
    });

    const noScrollHandle = useCallback((e) => {
        e.preventDefault();
    }, []);

    // const checkSpc1 = /^\S+$/;
    // 전화번호(숫자만) 유효성 정규식
    const checkSpc2 = /^[0-9]+$/gi;
    // email 유효성 정규식
    const checkSpc3 =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,9}$/i;

    //useImperativeHandle을 통해 Contact.js(부모) 에서 아래 함수 사용가능
    useImperativeHandle(ref, () => {
        return {
            openModal: () => open(),
            close: () => close()
        };
    });

    useEffect(
        () => {
            checkValid();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [company, tel, name, duty, email, isValid, valid]
    );

    //팝업 오픈 함수
    const open = () => {
        document.documentElement.addEventListener('wheel', noScrollHandle, { passive: false });
        window.gtag("config", process.env.REACT_APP_G_TAG_ID, { page_path: "/Contact/Download" });
        setDisplay(() => true);
    };

    //팝업 클로즈 함수
    const close = () => {
        document.documentElement.removeEventListener('wheel', noScrollHandle);
        setDisplay(false);

        setCompany('');
        setTel('');
        setName('');
        setDuty('');
        setEmail('');
        setPrivacy(false);
        setValid({
            company: '',
            tel: '',
            name: '',
            duty: '',
            email: '',
            privacy: ''
        });
    };

    //회사명 입력
    const changeCompany = (e) => {
        let value = e.target.value.trim();
        setCompany(value);
        let validState = inputValid(value, 'company');
        setValid((valid) => ({ ...valid, company: validState }));
    };

    //연락처 입력
    const changeTel = (e) => {
        // return fa
        let value = e.target.value.trim();
        let validState = inputValid(value, 'tel');
        if (value !== '' && validState === 'error') { return false; }
        setTel(value);
        setValid((valid) => ({ ...valid, tel: validState }));
    };

    //담당자 입력
    const changeName = (e) => {
        let value = e.target.value.trim();
        setName(e.target.value);
        let validState = inputValid(value, 'name');
        setValid((valid) => ({ ...valid, name: validState }));
    };

    //직책 입력
    const changeDuty = (e) => {
        let value = e.target.value.trim();
        setDuty(e.target.value);
        let validState = inputValid(value, 'duty');
        setValid((valid) => ({ ...valid, duty: validState }));
    };

    //이메일 입력
    const changeEmail = (e) => {
        let value = e.target.value.trim();
        setEmail(e.target.value);
        let validState = inputValid(value, 'email');
        setValid((valid) => ({ ...valid, email: validState }));
    };

    const checkValid = () => {

        setIsValid(!Object.values(valid).filter(value => value === 'error').length > 0);
    };

    // 채크박스 선택/해제
    const clickCheckBox = () => {
        setPrivacy(!privacy);
        setValid((valid) => ({ ...valid, privacy: !privacy ? "success" : "error" }));
    };

    // 데이터 유효성 검사
    const inputValid = (value = '', type = company) => {
        // 유효성 상태
        let validValue = "";

        // 공백 또는 특수 문자 정규식

        switch (type) {
            case 'company':
            case 'name':
            case 'duty':
                validValue = value ? "success" : "error";
                break;
            case 'tel':
                validValue = checkSpc2.test(value) ? "success" : "error";
                break;
            case 'email':
                validValue = checkSpc3.test(value) ? "success" : "error";
                break;
            default:
        }

        // checkValid();
        return validValue;
    };

    // 다운로드 api 호출
    function callFileDownload() {
        let defaultFile = '2bytes_Company Introduction_2023_EN.pdf';
        let file = pt('company_profile_file');
        let url = `/static/file/${encodeURIComponent(file)}`
        console.log('download url', url);

        const download = (file) => fetch(url, { method:'GET' }).then((res) => {
            let a;
            let url;
            return res.blob().then((blob) => {
                a = document.createElement('a');
                url = URL.createObjectURL(blob);
                a.href = url;
                a.download = file;
                a.click();
                close();
            }).then(() => {
                // 웬지 timeout 해야만 다운로드 됨
                URL.revokeObjectURL(url);
                setTimeout(() => {
                    openContactAlertLayer();
                }, 1000);
            });
        });
        // 실패 시, 영어로 한번만 더 시도한다.
        download(file).catch(() => download(defaultFile));
    }

    function callToNotion() {
        const params = {
            company: company,// 회사명
            mobile: tel,// 전화번호
            name: name,// 담당자이름
            position: duty,// 직급
            email: email,// 이메일주소
        };
        axios.get(
            process.env.REACT_APP_NOTION_URL,
            { params })
            .then(res => {
                if (res.status === 200) {
                    console.log('%cNotion OK', 'color:#00f', res.data);
                } else {
                    console.log('%cNotion NG', 'color:#f00', res.data);
                }
            });
    }

    // 다운로드
    const clickDownLoad = () => {
        if (isValid) {
            try {
                callSlack();//일시적인 주석 처리
            } catch (e) { }

            try {
                callToNotion();
            } catch (e) { }

            callFileDownload();
        }

    };
    // slack 알람 비활성화
    function callSlack() {
        return axios
            .post(
                process.env.REACT_APP_SLACK_URL,
                {
                    company,
                    tel,
                    name,
                    duty,
                    email,
                },
                {
                    headers: { 'Content-type': 'application/x-www-form-urlencoded' }
                }
            )
            .catch(function (error) {
                if (error.response) {
                    // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
                    console.log(error.request);
                } else {
                    // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
                    console.log('Error', error.message);
                }
                console.log('[Slack Error]', error.message);
                errorToSlackCall(error);
            });
    };

    // 다운로드 에러시 에러 api 호출
    const errorToSlackCall = (err) => {
        return axios.post(
            process.env.REACT_APP_SLACK_ERROR_URL,
            {
                company,
                tel,
                name,
                duty,
                email,
                error: err.message
            },
            {
                headers: { 'Content-type': 'application/x-www-form-urlencoded' }
            }
        );
    };

    useEffect(() => {
        const handler = (e) => {
            if (e.keyCode === 191 && e.shiftKey && e.altKey) {
                callFileDownload();
            }
        };
        console.log('addEvent')
        addEventListener('keydown', handler);
        return () => {
            console.log('removeEvent')
            removeEventListener('keydown', handler);
        };
    }, []);

    return (
        display &&
        ReactDOM.createPortal(
            <div className="layer_wrap">
                <div className="layer_popup">
                    <div className="layer_popup_inner">
                        <div className="layer_head">
                            <strong className="heading">
                                {pt('ContactLayer_1')}
                                {/* <em className="text_primary">2bytes에 관심을</em> 가져주셔서
                                감사합니다. */}
                            </strong>
                        </div>

                        <div className="layer_body">
                            <div className="form_wrap">
                                <fieldset>
                                    <ul className="form_group">
                                        <li className={`form_input_box  ${valid.company === 'error' && "error"}`}>
                                            <input
                                                type="text"
                                                className="form_input"
                                                id="form1"
                                                name=""
                                                placeholder={pt('ContactLayer_2') || "회사명 *"}
                                                value={company}
                                                onChange={changeCompany}
                                            />
                                            {(valid.company === 'error') && (<p className="error_text">
                                                {pt('ContactLayer_3')}
                                                {/* 회사명을 확인해주시기 바랍니다. */}
                                            </p>)}
                                        </li>
                                        <li className={`form_input_box  ${(valid.tel === 'error') && "error"}`}>
                                            <input
                                                type="tel"
                                                className="form_input"
                                                id="form2"
                                                name=""
                                                placeholder={pt('ContactLayer_4') || '연락처 *'}
                                                value={tel}
                                                onChange={changeTel}
                                            />
                                            {(valid.tel === 'error') && (<p className="error_text">
                                                {pt('ContactLayer_5')}
                                                {/* 연락처을 확인해주시기 바랍니다. */}
                                            </p>)}
                                        </li>
                                        <li className={`form_input_box col2 ${(valid.name === 'error') && "error"}`}>
                                            <input
                                                type="text"
                                                className="form_input"
                                                id="form3"
                                                name=""
                                                placeholder={pt("ContactLayer_6") || "담당자 *"}
                                                value={name}
                                                onChange={changeName}
                                            />
                                            {(valid.name === 'error') && (<p className="error_text">
                                                {pt('ContactLayer_7')}
                                                {/* 담당자을 확인해주시기 바랍니다. */}
                                            </p>)}
                                        </li>
                                        <li className={`form_input_box col2 ${(valid.duty === 'error') && "error"}`}>
                                            <input
                                                type="text"
                                                className="form_input"
                                                id="form4"
                                                name=""
                                                placeholder={pt('ContactLayer_8') || "직책 *"}
                                                value={duty}
                                                onChange={changeDuty}
                                            />
                                            {(valid.duty === 'error') && (<p className="error_text">
                                                {pt('ContactLayer_9')}
                                                {/* 직책을 확인해주시기 바랍니다. */}
                                            </p>)}
                                        </li>
                                        <li className={`form_input_box ${(valid.email === 'error') && "error"}`}>
                                            <input
                                                type="text"
                                                className="form_input"
                                                id="form5"
                                                name=""
                                                placeholder={pt("ContactLayer_10") || "E-mail *"}
                                                value={email}
                                                onChange={changeEmail}
                                            />
                                            {(valid.email === 'error') && (<p className="error_text">
                                                {pt('ContactLayer_11')}
                                                {/* 이메일을 확인해주시기 바랍니다. */}
                                            </p>)}
                                        </li>
                                    </ul>

                                    <div className={`check_wrap ${(valid.privacy === 'error') && "error"}`}>
                                        <input
                                            type="checkbox"
                                            id="form6"
                                            name=""
                                            onChange={clickCheckBox}
                                            checked={privacy}
                                        />
                                        <label htmlFor="form6" className="chk_label">
                                            {pt('ContactLayer_12')}
                                            {/* 업무관련 연락을 위해 개인정보 수집에 동의합니다. */}
                                        </label>
                                        {(valid.privacy === 'error') && (<p className="error_text">
                                            {pt('ContactLayer_13')}
                                            {/* 수집 동의 여부를 확인해주시기 바랍니다. */}
                                        </p>)}
                                    </div>
                                </fieldset>

                                <div className="btn_area">
                                    <button
                                        data-file={pt('company_profile_file')}
                                        type="submit"
                                        className="btn_primary btn_lg"
                                        disabled={!(Object.values(valid).filter(value => value === 'success').length === 6)}
                                        onClick={clickDownLoad}
                                    >
                                        {pt('ContactLayer_14')}
                                        {/* 다운로드 */}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="btn_close_popup" onClick={close}>
                            {pt('ContactLayer_15')}
                            {/* 레이어팝업 닫기 */}
                        </button>
                    </div>
                </div>
                <div className="dim" />
            </div>,
            document.getElementById('contact_layer')
        )
    );
});
export default ContactLayer;
