import { useRef, useEffect, useMemo, useState } from 'react';
import SectionBottom from '../layout/SectionBottom';
import ContactLayer from './ContactLayer';
import PortfolioCard from './PortfolioCard';
import axios from 'axios';

import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

// utils
const divisionRegExp = /[\n\/\\ ,-]+/;
const split = (str) => str?.split?.(divisionRegExp)?.map(e => e.toLowerCase().trim())?.filter(e => e) ?? [];

// Page
function Portfolio(props) {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));

    const [portfolios, setPortfolios] = useState([]);
    const [filterPart, setFilterPart] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const ContactLayerRef = useRef();
    const rootUrl = '/static/img/portfolio/';

    useEffect(() => {
        $(window).trigger('scroll');
        $(window).scrollTop(0);
    }, []);

    useEffect(() => {
        axios
            .get(t('url_portfolio_list'))
            .then(({ data }) => {
                return data.map((item) => {
                    item.companyImg = rootUrl + item.companyImg;
                    item.thumbImg = rootUrl + item.thumbImg;
                    // Excel에서 filterCondition 를 처리해서 filterConditions를 새로 만들어 필터링에 사용한다.
                    item.filterConditions = split(item.filterCondition);
                    return item;
                });
            })
            .then((list) => {
                // console.log({list});
                setPortfolios(list);
            });
    }, [i18n.language]);


    const filteredPortfolios = useMemo(() => {

        const filterConditions = split(filterPart);//string[]
        // console.log(JSON.stringify({ filterData: filterConditions }));

        if (filterConditions) {
            if (Array.isArray(filterConditions) && filterConditions.length) {
                return portfolios.filter((e) => {
                    return filterConditions.some((k) => {
                        // test
                        // if (e.title === 'PLAY TOGETHER') console.log(k, 'in', e.filterConditions, '?');
                        return e.filterConditions.includes(k);
                    });
                });
            } else {
                return portfolios;
            }
        }
        return portfolios;


    }, [portfolios, filterPart]);

    const items = useMemo(() => {
        if (isOpen) {
            return filteredPortfolios.map((item, index) => {

                return (
                    <PortfolioCard key={index} item={item}></PortfolioCard>
                );
            });
        } else {
            return filteredPortfolios.slice(0, 24).map((item, index) => {

                return (
                    <PortfolioCard key={index} item={item}></PortfolioCard>
                );
            });

        }

    }, [filteredPortfolios, isOpen]);

    // console.log(items);

    const setFilter = (filterName) => {
        setFilterPart(filterName);
    };

    const openPortfolios = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div id="container" className="cont_portfolio">
            <section className="section_top">
                <div className="page_cover">
                    <div className="inner">
                        <h2 className="cont_title">
                            {pt('Portfolio_1')}
                            {/* Our Works */}
                        </h2>
                        <p className="desc">
                            {pt('Portfolio_2')}
                            {/* 50 + 게임 개발사에 로컬라이제이션, <br />
                            <strong className="font_w_500">
                                LQA, QA, CS 등의 서비스를 제공하고 있습니다.
                            </strong> */}
                        </p>
                    </div>
                </div>

                <div className="tab_wrap text_tab">
                    <div className="tab_box">
                        <ul
                            className="tab_list"
                        >
                            <li className={`btn-filter ${filterPart === '' && "on"}`}
                                onClick={() => setFilter('')}>
                                <span >
                                    <strong className="text hidden_sm">
                                        {pt('Portfolio_All_pc')}
                                    </strong>
                                    <strong className="text visible_sm">
                                        {pt('Portfolio_All_mo')}
                                    </strong>
                                </span>
                            </li>
                            <li className={`btn-filter ${filterPart === 'Localization/LQA' && "on"}`}
                                onClick={() => setFilter("Localization/LQA")}>
                                <span >
                                    <strong className="text hidden_sm">
                                        {pt('Portfolio_Localization_pc')}
                                    </strong>
                                    <strong className="text visible_sm">
                                        {pt('Portfolio_Localization_mo')}
                                    </strong>
                                </span>
                            </li>
                            <li className={`btn-filter ${filterPart === 'PS/CM/CS' && "on"}`}
                                onClick={() => setFilter("PS/CM/CS")} >
                                <span >
                                    <strong className="text hidden_sm">
                                        {pt('Portfolio_PS_pc')}
                                    </strong>
                                    <strong className="text visible_sm">
                                        {pt('Portfolio_PS_mo')}
                                    </strong>
                                </span>
                            </li>
                            <li className={`btn-filter ${filterPart === 'Production/Lettering/Design' && "on"}`}
                                onClick={() => setFilter("Production/Lettering/Design")} style={{
                                    flexShrink: 0.9,
                                }}>
                                <span >
                                    <strong className="text hidden_sm">
                                        {pt('Portfolio_Production_pc')}
                                    </strong>
                                    <strong className="text visible_sm">
                                        {pt('Portfolio_Production_mo')}
                                    </strong>

                                </span>
                            </li>
                            <li className={`btn-filter ${filterPart === 'QA/FGT' && "on"}`}
                                onClick={() => setFilter("QA/FGT")} style={{
                                    flexShrink: 0.9,
                                }}>
                                <span >
                                    <strong className="text hidden_sm">
                                        {pt('Portfolio_QA_pc')}
                                    </strong>
                                    <strong className="text visible_sm">
                                        {pt('Portfolio_QA_mo')}
                                    </strong>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section_portfolio">
                <div className="inner">
                    <div className="portfolio_list_wrap">
                        <div className="m_portfolio_list_items list_open_type">
                            <ul className="list_items">{items}</ul>
                        </div>
                    </div>

                    {filteredPortfolios.length > 30 && (
                        <div className="btn_area">
                            <button
                                type="button"
                                className="btn_basic btn_lg btn_open bd_basic font-weight-500 col1_sm"
                                onClick={openPortfolios}
                            >
                                {isOpen ? (pt('Portfolio_9') || "접기") : (pt('Portfolio_10') || "전체보기")}
                            </button>
                        </div>
                    )}
                </div>
            </section>

            <SectionBottom />

            <ContactLayer ref={ContactLayerRef} />
        </div>
    );
}
export default Portfolio;
