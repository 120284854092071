/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useState, useRef } from 'react';
import ActiveLink from '../components/ActiveLink';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

function FamilySitesIcon() {
    return <>
        <svg width="9" height="6" viewBox="0 0 9 6" fill="none" css={css`
            display: none;
            @media (min-width:991px) {
                display: block;
            }
        `}>
            <path d="M4.1 5.46667C4.3 5.73333 4.7 5.73333 4.9 5.46667L8.4 0.8C8.64721 0.470382 8.41202 0 8 0H1C0.587977 0 0.352786 0.470382 0.6 0.8L4.1 5.46667Z" fill="#111111" />
        </svg>
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" css={css`
            display: none;
            @media (max-width:991px) {
                display: block;
                margin-right: 1.9rem;
            }
        `}>
            <path d="M8.87796 10L17.6584 1.21951L16.4389 -5.33066e-08L8.87796 7.56098L1.56089 0.243902L0.341373 1.46341L8.87796 10Z" fill="#444444"/>
        </svg>
    </>;
}

function FamilySites() {
    const menuRef = useRef();
    const familySites = [
        {
            href: 'https://pentapeakstudios.com',
            target: '_blank',
            label: 'Pentapeak Studios',
        },
        {
            href: 'https://spacegears.game',
            target: '_blank',
            label: 'Space Gears',
        }
    ];

    return (<Box sx={{
        fontSize: '1.6rem',
        color: '#111',
        position: 'relative',
        lineHeight: '4rem',
        cursor: 'pointer',
        userSelect: 'none',
        paddingLeft: '2.5rem',
        // paddingRight: '2rem',

        transform: 'translateY(-3rem)',

        '&:hover > .dropdown-content': {
            display: 'flex',
        },

        '@media (max-width: 991px)': {
            border: '1px solid #D5D3E4',
            transform: 'initial',
        }
    }}>
        <Box css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @media (max-width: 991px) {
                justify-content: space-between;
            }
        `}>
            <Box component="span" sx={{
                marginRight: '2rem',
            }}>FAMILY SITE</Box>
            <FamilySitesIcon />
        </Box>
        <Box
            className='dropdown-content'
            ref={menuRef}
            sx={{
                position: 'absolute',
                right: 0,
                display: 'none',
                flexDirection: 'column',
                border: '1px solid #D5D3E4',
                padding: '1rem 1.6rem',
                lineHeight: '3rem',

                '&>a': {
                    whiteSpace: 'nowrap'
                },
                '&>a:hover': {
                    color: '#743FA3',
                },
                '@media (max-width:991px)': {
                    width: '100%',
                    background: '#fff',
                    padding: '2rem',
                }
            }}>
                {
                    familySites.map((site, index) => (<a
                        key={`familySite-${index}`}
                        className="menu_dep1"
                        target={site.target}
                        href={site.href}
                        rel="noreferrer"
                    >{site.label}</a>))
                }
        </Box>
    </Box>);
}


export default function Footer() {
    const { t, i18n } = useTranslation();
    const pt = (v) => parse(i18n.t(v));
    console.debug(i18n.language)

    return (
        <footer id="footer">
            <div className="f_top">
                <div className="inner clear">
                    {/* 
                    <div className="f_left location">
                        <strong className="title">
                            {pt('Footer_0')}
                        </strong>
                        <div className="con">
                            <p>
                                {pt('Footer_1')}
                            </p>
                            <p className="en">
                                {pt('Footer_2')}
                            </p>
                        </div>
                    </div>
                    */}

                    <div className="f_right f_nav_wrap" style={{width:'100%'}}>
                        <nav className="nav_gnb">
                            <Box component="ul" className="nav_list_items" sx={{
                                justifyContent: 'space-between !important',
                                '@media (max-width:400px)':{
                                    justifyContent: 'flex-start !important',
                                    margin: 'auto !important',
                                }
                            }}>
                                <li className="nav_list">
                                    <ActiveLink
                                        activeClassName="on"
                                        className="menu_dep1"
                                        to={`Why2Bytes`}
                                        as="Why2Bytes"
                                    // onClick={onMobileCloseMenu}
                                    >
                                        {pt('Footer_3')}
                                    </ActiveLink>
                                </li>
                                <li
                                    className="nav_list"
                                // onMouseOver={onOpenMenu}
                                // onMouseOut={onOpenMenu}
                                >
                                    <ActiveLink
                                        className="menu_dep1"
                                        activeClassName="on"
                                        to={`Services/Localization`}
                                        as="Services"
                                    // onClick={onMobileCloseMenu}
                                    >
                                        {pt('Footer_14')}
                                    </ActiveLink>
                                    <div className="menu_dep2">
                                        <ul>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Services/Localization`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    {pt('Footer_5')}
                                                </ActiveLink>
                                            </li>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Services/QA`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    {pt('Footer_6')}
                                                </ActiveLink>
                                            </li>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Services/PlayerSupport`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    {pt('Footer_7')}
                                                </ActiveLink>
                                            </li>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Services/ContentMarketing`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    {pt('Footer_15')}
                                                </ActiveLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav_list">
                                    <ActiveLink
                                        activeClassName="on"
                                        className="menu_dep1"
                                        to={`OurWorks`}
                                    // onClick={onMobileCloseMenu}
                                    >
                                        {pt('Footer_8')}
                                    </ActiveLink>
                                </li>
                                <li className="nav_list">
                                    <a
                                        className="menu_dep1"
                                        target="_blank"
                                        href={i18n.language === "ko" ? "https://medium.com/2bytescorp" : "https://medium.com/bytetheworld"}
                                        rel="noreferrer"
                                    >
                                        {pt('Footer_9')}
                                    </a>
                                </li>
                                <li className="nav_list">
                                    <ActiveLink
                                        activeClassName="on"
                                        className="menu_dep1"
                                        to={`Contact`}
                                    // onClick={onMobileCloseMenu}
                                    >
                                        {pt('Footer_10')}
                                    </ActiveLink>
                                </li>
                                <li
                                    className="nav_list"
                                // onMouseOver={onOpenMenu}
                                // onMouseOut={onOpenMenu}
                                >
                                    <ActiveLink
                                        className="menu_dep1"
                                        activeClassName="on"
                                        to={`Careers/Life`}
                                        as="Careers"
                                    // onClick={onMobileCloseMenu}
                                    >
                                        {pt('Footer_11')}
                                        {/* Careers */}
                                    </ActiveLink>
                                    <div className="menu_dep2">
                                        <ul>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Careers/Life`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    Life at 2bytes
                                                </ActiveLink>
                                            </li>
                                            <li>
                                                <ActiveLink
                                                    activeClassName="on"
                                                    to={`Careers/Jobs`}
                                                // onClick={onMobileCloseMenu}
                                                >
                                                    Jobs at 2bytes
                                                </ActiveLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </Box>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="f_bottom">
                <div className="inner clear">

                    <p className="f_left copyright">
                        {pt('Footer_1')}<br />
                        {pt('Footer_12')}<br />
                    </p>

                    <div className="f_right sns">
                        <a
                            style={{
                                ...i18n.language !== 'ko' ? {display: 'none'} : {}
                            }}
                            className="facebook"
                            target="_blank"
                            href="https://www.facebook.com/2bytescorp"
                            rel="noreferrer"
                        >
                            <i className="facebook" />
                        </a>
                        <a  
                            className="youtube"
                            target="_blank"
                            href="https://www.youtube.com/channel/UCmK0q-ApIEUODuyS1h-owdg"
                            rel="noreferrer"
                        >
                            <i className="youtube" />
                        </a>
                    </div>
                </div>
                <Box component="div" className='inner' sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 991px)': {
                        flexDirection: 'column',
                    }
                }}>
                    <Link to={`Privacy`} className="privacyBtn" target="_blank" css={css`
                        font-weight:700;
                        margin-bottom: 2rem;
                    `}>
                        {pt('Footer_13')}
                    </Link>
                    <FamilySites />
                </Box>
            </div>
        </footer>
    );
};
